<template>
  <div id="app">
    <Header />
    <HeroSection />
    <SoftwareSection />
    <AppDevelopmentSection />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import HeroSection from './components/HeroSection.vue'
import SoftwareSection from './components/SoftwareSection.vue'
import AppDevelopmentSection from './components/AppDevelopmentSection.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    HeroSection,
    SoftwareSection,
    AppDevelopmentSection,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0; /* You might want to remove extra margins */
}

/* Add any global styling here if needed */
</style>