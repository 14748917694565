<template>
    <section class="app-development">
      <div class="container">
        <h2>Mobile App Development</h2>
        <div class="apps">
          <div class="app">
            <img src="@/assets/ios-app.png" alt="iOS App Development" />
            <h3>iOS App Development</h3>
            <p>Our team builds top-notch iOS applications...</p>
          </div>
          <div class="app">
            <img src="@/assets/android-app.png" alt="Android App Development" />
            <h3>Android App Development</h3>
            <p>We create Android applications for all platforms...</p>
          </div>
          <div class="app">
            <img src="@/assets/uiux-design.png" alt="UI/UX Design" />
            <h3>UI/UX Design</h3>
            <p>We provide intuitive and engaging UI/UX design...</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AppDevelopmentSection'
  }
  </script>
  
  <style scoped>
  .app-development {
    background-color: #ffffff;
    padding: 80px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .apps {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  
  .app {
    max-width: 300px;
  }
  
  .app img {
    max-width: 200px; /* Increased the size of app images to twice the original size */
    margin-bottom: 20px;
  }
  
  .app h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .app p {
    font-size: 16px;
    color: #666;
  }
  </style>