<template>
    <header class="header">
      <div class="container">
        <div class="logo">
          <img src="@/assets/logo.png" alt="Logo" />
        </div>
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#footer">Contact Us</a></li> <!-- Update here -->
          </ul>
        </nav>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'AppHeader'
  }
  </script>
  
  <style scoped>
  .header {
    background: linear-gradient(to right, #000000, #553c9a, #b92b27);
    color: white;
    padding: 20px 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .logo img {
    height: 160px;
  }
  
  nav ul {
    list-style: none;
    display: flex;
  }
  
  nav ul li {
    margin: 0 20px;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }
  </style>