<template>
    <section class="hero">
      <div class="container">
        <div class="text-content">
          <h1>Idaho Software and Application Development</h1>
          <p>We specialize in developing custom software solutions to meet your business needs.</p>
          <button>Contact Us</button>
        </div>
        <div class="image-content">
          <img src="@/assets/hero-image.png" alt="Software Development" />
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'HeroSection'
  }
  </script>
  
  <style scoped>
  .hero {
    background: linear-gradient(to right, #000000, #553c9a, #b92b27);
    color: white;
    padding: 100px 0;
    text-align: center;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .text-content {
    max-width: 50%;
  }
  
  .text-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .text-content p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  button {
    background-color: #ff007b;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  
  button:hover {
    background-color: #ff4081;
  }
  
  .image-content img {
    max-width: 800px; /* Increase the image size to make it about twice the size */
    border-radius: 15px;
  }
  </style>