<template>
    <section class="software-section">
      <div class="container">
        <h2>Custom Software Development</h2>
        <p class="section-description">We specialize in developing software tailored to your business...</p>
        <div class="features">
          <div class="feature">
            <img src="@/assets/efficient-workflow.png" alt="Efficient Workflow" />
            <h3>Efficient Workflow</h3>
            <p>We create software that optimizes your processes...</p>
          </div>
          <div class="feature">
            <img src="@/assets/user-friendly.png" alt="User-Friendly Interfaces" />
            <h3>User-Friendly Interfaces</h3>
            <p>Our applications are designed with the user experience in mind...</p>
          </div>
          <div class="feature">
            <img src="@/assets/innovative-solutions.png" alt="Innovative Solutions" />
            <h3>Innovative Solutions</h3>
            <p>Our team brings up-to-date solutions...</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'SoftwareSection'
  }
  </script>
  
  <style scoped>
  .software-section {
    background-color: #f9f9f9;
    padding: 80px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .section-description {
    font-size: 18px;
    margin-bottom: 40px;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  
  .feature {
    max-width: 300px;
  }
  
  .feature img {
    max-width: 200px; /* Increase the image size to twice the size */
    margin-bottom: 20px;
  }
  
  .feature h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .feature p {
    font-size: 16px;
    color: #666;
  }
  </style>