<template>
    <footer class="footer" id="footer">
      <div class="footer-content">
        <p>&copy; 2024 Idaho Software Development. All rights reserved.</p>
        <ul class="footer-links">
          <li><a href="#contact">Contact</a></li>
        </ul>
        <div class="contact-info">
          <p>Phone: 208-250-1154</p>
          <p>Email: <a href="mailto:admin@idsoftwaredev.com">admin@idsoftwaredev.com</a></p>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooter'
  }
  </script>
  
  <style scoped>
  .footer {
    background-color: #2c3e50;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .footer-links li {
    margin: 0 10px;
  }
  
  .footer-links a {
    color: #ff007b;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .contact-info {
    margin-top: 10px;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  .contact-info a {
    color: #ff007b;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  </style>